<template>
  <div class="columns is-multiline has-padding-50 is-marginless">
    <div class="column is-12-mobile">
      <div class="box">
        <agent-stats-chart :user-id="userId" />
      </div>
    </div>
    <div class="column is-12">
      <collapse title="Tasks">
        <b-tabs>
          <b-tab-item label="Open">
            <agents-tasks-table
              :participant-id="userId"
              :user-id="userId"
              include-deleted
              allow-filtering
              admin-context
              :is-open="true"
            />
          </b-tab-item>
          <b-tab-item label="Closed">
            <agents-tasks-table
              :participant-id="userId"
              :user-id="userId"
              include-deleted
              allow-filtering
              admin-context
              :is-open="false"
            />
          </b-tab-item>
        </b-tabs>
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Activity logs">
        <logs-table :user-id="userId" :allow-create="false" admin-context />
      </collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminUser",
  components: {
    "agents-tasks-table": () => import("@shared/tasks/_agentsTasksTable"),
    "agent-stats-chart": () => import("@shared/agents/_agentStatsChart"),
    "logs-table": () => import("@shared/logs/_logsTable")
  },
  data() {
    return {
      userId: this.$route.params.userId
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    isReseller() {
      return this.$store.getters["user/isReseller"](this.userId);
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"](this.userId);
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]();
    }
  },
  created() {
    if (!this.isAdmin) {
      return this.$router.push({ path: "/admin/dashboard" });
    }
    this.$store
      .dispatch("user/observeUser", { userId: this.userId })
      .catch(this.agentNotFound);
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.userId });
  },
  methods: {
    agentNotFound() {
      this.$router.push({ path: `/admin/agents` });
      this.$toast.open({
        message: "Agent not found",
        type: "is-danger"
      });
    }
  }
};
</script>
